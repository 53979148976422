<template>
  <div class="wrap">
    <div class="banner" :style="{backgroundImage: 'url(' + imgUrl.banner + ')'}">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">病区24H监护中心与夜间病床管理系统</p>
      </div>
    </div>
    <div class="content">
      <div class="en t2"></div>
      <div class="inner product">
        <h2>产品<em>介绍</em></h2>
        <div class="details2">
          <div class="img">
            <ul>
              <li>
                <img :src="imgUrl.img1"
                     alt="">
                <h3>24H无扰式生命体征监护</h3>
              </li>
              <li>
                <img :src="imgUrl.img2"
                     alt="">
                <h3>心电监护仪</h3>
              </li>
              <li class="p3">
                <img src="../../assets/images/monitor-img3.png"
                     alt="">
                <h3>病区24H监护中心<br />与夜间病床管理系统</h3>
              </li>
              <li>
                <img :src="imgUrl.img4"
                     alt="">
                <h3>床旁24小时无扰式监控终端</h3>
              </li>
            </ul>
          </div>
          <div class="text">
            <p>病区24小时监护中心包含患者在院意外防控与定位系统、夜间无扰式生命体征监护系统等系统与功能病区24小时监护中心包含患者在院意外防控与定位系统、夜间无扰式生命体征监护系统等系统与功能病区24小时监护中心包含患者在院意外防控与定位系统、夜间无扰式生命体征监护系统等系统与功能。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content bg">
      <div class="en t3"></div>
      <div class="inner product">
        <h2><em>服务</em>与<em>功能</em></h2>
        <div class="p-list">
          <img class="img"
               :src="imgUrl.monitor"
               alt="">
          <ul>
            <li class="p1">
              <em></em>
              <h3>病区24小时监护系统</h3>
            </li>
            <li class="p2">
              <em></em>
              <h3>医护患视频语音沟通系统</h3>
            </li>
            <li class="p3">
              <em></em>
              <h3>患者在院意外防控系统</h3>
            </li>
            <li class="p4">
              <em></em>
              <h3>病区夜间监护系统</h3>
            </li>
            <li class="p5">
              <em></em>
              <h3>夜间无扰式生命体征监护系统</h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getAliyunImg from '../../utils/aliyun'

export default {
  name: 'monitor',
  created () {
    this.imgUrl.banner = getAliyunImg.signatureUrl('ytwd/gw/monitor-banner.png')
    this.imgUrl.img1 = getAliyunImg.signatureUrl('ytwd/gw/monitor-img1.png')
    this.imgUrl.img2 = getAliyunImg.signatureUrl('ytwd/gw/monitor-img2.png')
    // this.imgUrl.img3 = getAliyunImg.signatureUrl('ytwd/gw/monitor-img3.png')
    this.imgUrl.img4 = getAliyunImg.signatureUrl('ytwd/gw/monitor-img4.png')
    this.imgUrl.monitor = getAliyunImg.signatureUrl('ytwd/gw/monitor.png')
  },
  data () {
    return {
      imgUrl: {
        banner: '',
        img1: '',
        img2: '',
        img3: '',
        img4: '',
        monitor: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./css/style.scss";
.banner {
  // background-image: url("../../assets/images/monitor-banner.png");
  .icon {
    background-image: url("../../assets/images/monitor-icon.png");
  }
}
.content {
  .en.t3 {
    background-color: #fff;
    top: 0;
  }
  &.bg {
    background: #edfffe;
    padding-top: 69px;
    margin-top: 40px;
    .product h2 {
      position: absolute;
      top: -68px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .product .details2 {
    padding-top: 68px;
    .img {
      position: relative;
      left: 1px;
      ul {
        display: flex;
        li {
          width: 300px;
          height: 350px;
          margin-left: -1px;
          border: 1px solid #00dcd4;
          position: relative;
          overflow: hidden;
          h3 {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 60px;
            line-height: 60px;
            font-size: 16px;
            color: #fff;
            text-align: center;
            background: rgba($color: #000000, $alpha: 0.63);
          }
          &.p3 h3 {
            font-size: 18px;
            line-height: 30px;
            top: 50%;
            margin-top: -30px;
            background: none;
          }
        }
      }
    }
    .text {
      width: 1197px;
      padding: 21px 32px;
      font-size: 18px;
      color: #6a6a6a;
      line-height: 30px;
      border: 1px solid #00dcd4;
      border-top: 0;
    }
  }
}

.p-list {
  height: 638px;
  padding: 50px 0 30px;
  position: relative;
  img {
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  ul {
    position: absolute;
    top: 0;
    left: 0;
    li {
      width: 141px;
      height: 141px;
      padding: 18px;
      background-color: #fff;
      border-radius: 50%;
      // box-shadow: 5px 5px 10px rgba($color: #00dcd4, $alpha: 0.14);
      // box-shadow: 5px #00dcd4;
      // box-shadow: 2px 2px 5px 2px #00dcd4;
      box-shadow: 0px 0px 15px -3px #00dcd4;
      position: absolute;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-direction: column;
      // float: left;
      // overflow: hidden;
      em {
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #f0fefe;
        // margin-top: 104px;
      }
      h3 {
        color: #6a6a6a;
        font-size: 16px;
        text-align: center;
        width: 300px;
        position: absolute;
        left: -80px;
        bottom: -30px;
      }
      &.p1 {
        left: 0;
        top: 390px;
        em {
          background-image: url("../../assets/images/monitor-icon1.png");
        }
      }
      &.p2 {
        left: 217px;
        top: 150px;
        em {
          background-image: url("../../assets/images/monitor-icon2.png");
        }
      }
      &.p3 {
        left: 530px;
        top: 50px;
        em {
          background-image: url("../../assets/images/monitor-icon3.png");
        }
      }
      &.p4 {
        left: 838px;
        top: 150px;
        em {
          background-image: url("../../assets/images/monitor-icon4.png");
        }
      }
      &.p5 {
        left: 1055px;
        top: 390px;
        em {
          background-image: url("../../assets/images/monitor-icon5.png");
        }
      }
    }
  }
}
</style>
